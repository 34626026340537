import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { inputs } from '../../claimDetailsHelpers/inputs';
import { currencyFormat } from '../../../../../utils/currencyFormat';
import { getDiscountCurrency } from '../../reviewPanelHelpers/calculateTotal';
import { salesTaxList } from '../../reviewPanelHelpers/salesTaxList';
import { t } from '../../../../../../service/localization/i18n';
import { getPractitionerByGuid } from '../../../../../utils/getPractitioner';

const DataTableSection = ({ data }) => {
  const clinic = useSelector((state) => state.clinic);
  const [expandedRows, setExpandedRows] = useState({});
  const tableData = data[inputs().privateService.descriptionName];

  //set expanded rows
  useEffect(() => {
    const rowsWithNote = tableData?.filter((i) => i.note);
    tableData && setExpandedRows(Object.fromEntries(rowsWithNote?.map((i) => [i.id, true])));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const rowExpansionTemplate = (data) => {
    return <div className="flex">{data.note}</div>;
  };

  const quantityBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <span className="text-left">{rowData?.quantity}</span>
      </div>
    );
  };

  const discountBodyTemplate = (rowData) => {
    let discountValue = rowData['discount'] === 0 || !rowData['discount'] ? '―' : currencyFormat(getDiscountCurrency(rowData));

    if (rowData['discount'] === 0 || !rowData['discount']) {
      discountValue = '―';
    } else {
      if (rowData.discountUnits === 'percent') discountValue = `${rowData.discount}%`;
      if (rowData.discountUnits !== 'percent') discountValue = currencyFormat(getDiscountCurrency(rowData));
    }

    return (
      <div className="flex">
        <span>{discountValue}</span>
      </div>
    );
  };

  const providerBodyTemplate = (rowData) => {
    const practitioner = rowData.licenceNumber ? `${rowData.treatedBy}, #${rowData.licenceNumber}` : `${rowData.treatedBy}`;
    return (
      <div className="flex flex-column gap-1">
        <span className="text-left">{practitioner}</span>
        <span className="text-left text-600"> {practitionerNumbersLayout()}</span>
      </div>
    );
  };

  const practitionerNumbersLayout = (practitionerGuid) => {
    const currentPractitioner = getPractitionerByGuid(practitionerGuid);
    const licenceNumber = currentPractitioner?.LicenceNumber;
    const practitionerNumber = currentPractitioner?.PractitionerNumber;
    let numbers = '';

    if (licenceNumber && practitionerNumber) numbers = `${licenceNumber}/${t('MSP')} ${licenceNumber}`;
    if (licenceNumber && !practitionerNumber) numbers = `${licenceNumber}`;
    if (!licenceNumber && practitionerNumber) numbers = `${practitionerNumber}`;

    if (!numbers) return null;

    return <span>{numbers}</span>;
  };

  const salesTaxBodyTemplate = (rowData) => {
    const currentTax = salesTaxList.find((i) => i.name === rowData.salesTax)?.text;
    return (
      <div className="flex">
        <span className="text-left">{currentTax}</span>
      </div>
    );
  };

  const serviceBodyTemplate = (rowData) => {
    const isService = rowData.category !== 'product';
    const serviceText = rowData.unit && isService ? `${rowData.label}, ${rowData.duration} ${rowData.unit}` : `${rowData.label}`;

    return (
      <div className="flex">
        <span className="text-left">{serviceText}</span>
      </div>
    );
  };

  const serviceNumberBodyTemplate = (_, { rowIndex }) => {
    return rowIndex + 1;
  };

  const amountBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <span className="text-left">{currencyFormat(rowData?.amount)}</span>
      </div>
    );
  };

  const feeAmountBodyTemplate = (rowData) => {
    return (
      <div className="flex">
        <span className="text-left">{currencyFormat(rowData?.feeAmount)}</span>
      </div>
    );
  };

  return (
    <div className="datatable-responsive-layout privateInvoicePreviewDataTable">
      <DataTable
        dataKey="id"
        className="p-datatable-responsive-layout"
        value={tableData}
        expandedRows={expandedRows}
        rowExpansionTemplate={(e) => rowExpansionTemplate(e)}
      >
        <Column className="serviceNumberBodyTemplate" header="No." body={serviceNumberBodyTemplate} />
        <Column className="serviceBodyTemplate" field="label" header={t('Service_Product.2').toUpperCase()} body={serviceBodyTemplate} />
        <Column className="providerBodyTemplate" field="label" header={t('Provider').toUpperCase()} body={providerBodyTemplate} />
        <Column className="quantityBodyTemplate" field="quantity" header={t('Quantity.1').toUpperCase()} body={quantityBodyTemplate} />
        <Column className="amountBodyTemplate" field="amount" header={t('Fee').toUpperCase()} body={amountBodyTemplate} />
        <Column className="feeAmountBodyTemplate" field="feeAmount" header={t('Amount').toUpperCase()} body={feeAmountBodyTemplate} />
        {tableData?.some((i) => i.discount) && (
          <Column
            className="discountBodyTemplate"
            field="discount"
            header={t('Discount.1').toUpperCase()}
            body={(params) => discountBodyTemplate(params)}
          />
        )}
        <Column className="salesTaxBodyTemplate" field="salesTax" header={t('Tax').toUpperCase()} body={salesTaxBodyTemplate} />
      </DataTable>
    </div>
  );
};

export default DataTableSection;
