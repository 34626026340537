import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import MenuLayout from './MenuLayout';
import MenuItemRender from './MenuItemRender';
import GlobalSearch from '../GlobalSearch';
import BetaBadge from '../../../../components/Misc/Badges/BetaBadge';

import { elementIDs } from '../../../config/elementIDsConfig';
import { routes } from '../../../../routes/routes';
import { t } from '../../../../service/localization/i18n';

const CreatePatient = () => {
  const history = useHistory();

  const { isMobileOnly, isMobile } = useSelector((state) => state.core.window);
  const labelIcon = isMobileOnly ? 'pi pi-camera' : 'pi pi-upload';

  const items = useMemo(() => {
    const items = [
      {
        label: <div className="font-bold">{`Create patient`}</div>,
        items: [
          {
            id: elementIDs.addNewPatient,
            label: t('New'),
            icon: 'pi pi-plus',
            command: () => history.push(`${routes.createPatient.path}/new`),
            template: (item) => <MenuItemRender item={item} />
          },
          {
            id: elementIDs.addNewborn,
            label: 'Newborn',
            icon: 'pi pi-plus',
            command: () => history.push(`${routes.createNewborn.path}/new`),
            template: (item) => <MenuItemRender item={item} />
          }
        ]
      }
    ];

    const labelItem = {
      id: elementIDs.addNewPatientWithAutofill,
      label: (
        <div className="flex align-items-center gap-2">
          {t('Upload Label')}
          <BetaBadge />
        </div>
      ),
      icon: labelIcon, // Set the icon conditionally based on `isMobileOnly`
      command: () =>
        history.push({
          pathname: `${routes.createPatient.path}/new`,
          state: { activateAutofill: true }
        }),
      template: (item) => <MenuItemRender item={item} />
    };

    if (!isMobile) {
      items[0].items.unshift(labelItem);
    }

    items.push({
      template: () => (
        <div className="pt-3 pb-2 px-2">
          <GlobalSearch name="patient" placeholder="Search patient" />
        </div>
      )
    });

    return items;
  }, [history, isMobile]);

  return <MenuLayout model={items} />;
};

export default CreatePatient;
