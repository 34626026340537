import React from 'react';
import { FileUpload } from 'primereact/fileupload';
import { ButtonWithBadge } from '../../Buttons';
import useDaysheet from '../../../modules/hooks/useDaysheet';
import { t } from '../../../service/localization/i18n';
import cx from 'classnames';

import './DaysheetUploadButton.scss';

const DaysheetUploadButton = ({ outlined, text, label, buttonBadgeClassName, hideNewBadge, ...props }) => {
  const { isUploading, allowedFileExtensions, handleUpload } = useDaysheet();
  const fileUploadRef = React.useRef(null); // Reference to FileUpload component

  const onUpload = (event) => {
    const file = event.files[0];
    handleUpload(file, () => {
      props.onUpload?.(); // Call additional success callback if provided
      fileUploadRef.current.clear(); // Clear the FileUpload state
    });
  };

  return (
    <ButtonWithBadge value={hideNewBadge ? '' : 'New'} rootClassName={buttonBadgeClassName}>
      <FileUpload
        {...props}
        ref={fileUploadRef} // Attach ref to FileUpload
        name="daysheet"
        customUpload
        uploadHandler={onUpload}
        accept={allowedFileExtensions}
        maxFileSize={200 * 1024 * 1024}
        mode="basic"
        auto={true}
        maxFiles={1}
        disabled={isUploading}
        className="w-full"
        chooseOptions={{
          label: label || t('Upload_daysheet'),
          icon: isUploading ? 'pi pi-spin pi-spinner' : 'pi pi-upload',
          className: cx('w-full', outlined ? 'p-button-outlined' : '', text ? 'p-button-text' : '', props.className),
          ...props.chooseOptions
        }}
      />
    </ButtonWithBadge>
  );
};

export default DaysheetUploadButton;
