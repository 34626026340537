import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataScroller } from 'primereact/datascroller';
import { ScrollTop } from 'primereact/scrolltop';

import Context from '../../Context';
import LegoTable from './LegoTable';
import AlphabetLinks from './LegoAlphabetLinks';
import SearchPanel from './LegoSearchPanel';
import Chips from './LegoChips';
import Filters from './LegoFilters';
import RemoveFiltersForMoreRecords from './RemoveFiltersForMoreRecords';

import { defaultPageSize, showInCardPageSize } from '../config/defaultValuesConfig';
import { setDropdownValueWithKeyboard } from '../utils/setDropdownWithKeyboard';
import { isNumeric } from '../utils/isNumeric';

import cx from 'classnames';
import * as Helper from '../../Helper';
import { t } from '../../service/localization/i18n';
import classes from './LegoView.module.scss';
import { sessionStorageKeys } from '../config/localStorageKeysConfig';
import { isMoneyPage } from '../../routes/routeMatchers';

/**
 *
 * @param {*} props View Model object
 */
const LegoView = ({
  columns,
  actions,
  dataFieldFilters,
  onExportClick,
  dataOnPageInplace,
  dataOnPageAppend,
  dataTotalRows,
  dataSourceAppend,
  isFetching,
  dataTableExpandedRows, // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
  //setDataTableExpandedRows,  // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
  dataScrollerClass,
  showChips = false,
  showInCard = false,
  showOnPatientDetailsScreen = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const { isMobile, isMobileDataTableView, isMobileOnly, isTablet, isLandscape, iPadInPortrait, iPadProInLandscape } = useSelector(
    (state) => state.core.window
  );
  const { showDataScroller, layoutMode } = useSelector((state) => state.core);

  const contextValue = { sortby: props.sortby, selectedCodes: props.dataPage.selectedCodes || {} };
  const fitRows = props.pageSize; // Math.ceil(tableHeight / 50); // [VB] will re-visit fitRows in future
  const mobileDefaultPageSize = props.pageSize;
  const filters = props.filters && props.filters ? props.filters : {};
  const totalFound = props.dataPage.totalRecords;
  const totalAlter = props.dataPage.totalRecordsAlter;

  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedRows, setExpanded] = useState({});
  const [query, setQuery] = useState(props.dataQuery);
  const [invalidFields, setInvalid] = useState({});
  const [showSidebarFilters, setShowSidebarFilters] = useState(false);
  const [first, setFirst] = useState(0);

  const showRemoveFiltersForMoreRecords = totalAlter !== undefined && totalAlter !== totalFound && isMobileOnly;

  // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
  // useEffect(() => {
  //   dispatch(setDataTableExpandedRows(expandedRows));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [expandedRows]);

  // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
  // useEffect(() => {
  //   props.dataSourceInplace?.length === Object.keys(dataTableExpandedRows)?.length ? setIsExpanded(true) : setIsExpanded(false);
  // }, [props.dataSourceInplace, dataTableExpandedRows]);

  useEffect(() => {
    // [KS] CMO-898 - Keep extended on when user Views claim and goes back to the invoices table
    setExpanded(dataTableExpandedRows);

    const page = props.dataPage.page === 0 ? props.dataPage.page : props.dataPage.page - 1;
    if (showDataScroller) {
      if (!isMobileOnly) dataOnPageInplace({ page, rows: props.dataPage.rows, pageSize: props.dataPage.rows, noNextPage: true });
      // if (isMobileOnly) {
      //   if (dataSourceAppend.length === 0) dataOnPageAppend({ page: null, rows: mobileDefaultPageSize, pageSize: mobileDefaultPageSize })
      // } else {
      //   dataOnPageInplace({ page, rows: props.dataPage.rows, pageSize: props.dataPage.rows, noNextPage: true });
      // }
    } else {
      const localStoragePageSize = JSON.parse(localStorage.getItem(props.localStoragePageSizeKey))
        ? JSON.parse(localStorage.getItem(props.localStoragePageSizeKey))
        : defaultPageSize;

      const currentPageSize = showInCard
        ? showInCardPageSize
        : !showInCard && props.pageSize === showInCardPageSize
        ? localStoragePageSize
        : props.pageSize;

      // CMO-1625 - New skeleton for patients profile
      if (!props.showSkeleton) {
        const lockClaimFilters = JSON.parse(sessionStorage.getItem(sessionStorageKeys.lockClaimFilters));
        const claimFilters = JSON.parse(sessionStorage.getItem(sessionStorageKeys.claimsListFilters));

        if (lockClaimFilters && claimFilters && isMoneyPage()) {
          dataOnPageInplace({
            filters: claimFilters.filters,
            sortField: claimFilters.sortby?.split('_')[0],
            sortOrder: claimFilters.sortby?.split('_')[1],
            page: claimFilters.page === 0 ? claimFilters.page : claimFilters.page - 1,
            pageSize: claimFilters.pageSize,
            rows: currentPageSize,
            noNextPage: true
          });
        } else {
          dataOnPageInplace({
            page,
            rows: currentPageSize,
            pageSize: currentPageSize,
            noNextPage: true
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showSkeleton]);

  useEffect(() => {
    setQuery(props.dataPage.filters);
  }, [props.dataPage.filters]);

  const validateDate = (field_id, value) => {
    const extraClass = Helper.isValidPartialDate(value) ? '' : 'p-invalid p-error';
    const arr = { [field_id]: extraClass };
    setInvalid(arr);
    if (!arr[field_id]) isMobileOnly ? dataOnPageAppend(props.dataPage) : dataOnPageInplace(props.dataPage);
  };

  const validateMoney = (field_id, value) => {
    const extraClass = isNumeric(value) ? '' : value !== '' ? 'p-invalid p-error' : '';
    const arr = { [field_id]: extraClass };
    setInvalid(arr);
    if (!arr[field_id]) isMobileOnly ? dataOnPageAppend(props.dataPage) : dataOnPageInplace(props.dataPage);
  };

  const setLazyLoad = (e) => {
    if (first !== 0 && first !== e.first) {
      setFirst(e.first);
      props.onLazyLoad(e);
      // create new data and load it.
    }
    if (first === 0) props.onLazyLoad(e);
  };

  const resetPageOnViewSwitch = () => {
    if (!isMobileOnly && props.dataPage.page !== 0) {
      props.dataPage.page = 0; // Reset page when switching from mobile to desktop
    }
  };

  // Call this function when view switch is detected
  resetPageOnViewSwitch();

  const setFieldFilter = useCallback(
    (e, field_id, field_type, tableFilters, sortby) => {
      let value = e.target?.value ? e.target.value : typeof e.value === 'number' ? `${e.value}` : e.value || '';
      props.dataPage.filters = tableFilters ? { ...tableFilters, [field_id]: value } : { ...filters, [field_id]: value };
      // props.dataPage.page = 0;
      props.dataPage.page = props.dataPage.page === 0 ? props.dataPage.page : props.dataPage.page - 1;
      props.dataPage.sortby = sortby || props.dataPage.sortby;

      if (tableFilters && field_id === 'Period' && tableFilters['ServiceDate'] === 'derived' && value.length < 8) {
        value = 'derived';
      }

      if (!tableFilters && field_id === 'Period' && value === '') {
        props.dataPage.filters.ServiceDate = '';
        props.dataPage.filters.PeriodFrom = '';
        props.dataPage.filters.PeriodTo = '';
      }

      if (field_id === 'DoctorGuid') {
        props.dataPage.filters = { ...filters, [field_id]: value, ['DoctorName']: '' };
      }

      if (props.columnFilter && typeof props.columnFilter === 'function') {
        dispatch(props.columnFilter(props.dataPage.filters));
      }

      switch (field_type) {
        case 'dropdown':
          const text = e.originalEvent.target.textContent;
          if (text !== '') value = text;
          if (value === 'All') value = '';
          if (field_id === 'StatusText') value = setDropdownValueWithKeyboard(e, props.dropdownFilters.allStatuses);
          if (field_id === 'InvoiceType') value = setDropdownValueWithKeyboard(e, props.dropdownFilters.invoiceTypes);

          props.dataPage.filters = { ...filters, [field_id]: value };
          isMobileOnly ? dataOnPageAppend(props.dataPage) : dataOnPageInplace(props.dataPage);
          break;

        case 'date':
          validateDate(field_id, value);
          break;

        case 'money':
          validateMoney(field_id, value);
          break;

        default:
          isMobileOnly ? dataOnPageAppend(props.dataPage) : dataOnPageInplace(props.dataPage);
          break;
      }
    },
    [props.dataPage.filters, props.dataPage.page, filters]
  );

  return (
    <Context.Provider value={contextValue}>
      <div className="legoViewWrap">
        {/* <SubMenu isMobileOnly={isMobileOnly}>
          {props.subMenu()}
        </SubMenu> */}

        {!showInCard && (
          <SearchPanel
            isMobile={isMobile}
            layoutMode={layoutMode}
            iPadProInLandscape={iPadProInLandscape}
            filters={props.filters}
            onAddClick={props.onAddClick}
            showAddNewOverlay={props.showAddNewOverlay}
            addNewOverlayItems={props.addNewOverlayItems}
            searchPanelComponent={props.searchPanelComponent}
            showOnPatientDetailsScreen={showOnPatientDetailsScreen}
            setShowSidebarFilters={setShowSidebarFilters}
          />
        )}

        {!showInCard && (
          <Filters
            isMobile={isMobile}
            isFetching={isFetching}
            filters={props.filters}
            showSidebarFilters={showSidebarFilters}
            totalRecords={props.dataPage.totalRecords}
            clearFilters={props.clearFilters}
            filtersTitle={props.filtersTitle}
            setShowSidebarFilters={setShowSidebarFilters}
          >
            {props.filterComponent()}
          </Filters>
        )}

        {!isMobileOnly && !showInCard && showChips && (
          <Chips
            query={query}
            filters={filters}
            layoutMode={layoutMode}
            totalAlter={totalAlter}
            totalFound={totalFound}
            columns={columns}
            isMobile={isMobile}
            isMobileOnly={isMobileOnly}
            iPadProInLandscape={iPadProInLandscape}
            extraFields={props.extraFields}
            clearFilters={props.clearFilters}
            showOnPatientDetailsScreen={showOnPatientDetailsScreen}
            clearFiltersExceptFreetext={props.clearFiltersExceptFreetext}
            setFieldFilter={setFieldFilter}
          />
        )}

        {showRemoveFiltersForMoreRecords && (
          <RemoveFiltersForMoreRecords
            className={cx('my-3', classes.RFFMRHeight)}
            totalAlter={totalAlter}
            totalFound={totalFound}
            clearFiltersExceptFreetext={props.clearFiltersExceptFreetext}
          />
        )}

        {props.showAlpha && (
          <AlphabetLinks
            setFieldFilter={setFieldFilter}
            setExpanded={setExpanded}
            page_info={props.dataPage}
            dataOnPageAppend={dataOnPageAppend}
            dataOnPageInplace={dataOnPageInplace}
          />
        )}

        {!showInCard && <ScrollTop className="customScrolltop" style={props.scrollUpPosition} icon="pi pi-arrow-up" />}

        {isMobileOnly && showDataScroller ? (
          <DataScroller
            className={cx('p-datatable-striped', isTablet && !isLandscape ? 'mt-1' : dataScrollerClass)}
            value={dataSourceAppend}
            itemTemplate={props.rowTemplateAppend}
            rows={mobileDefaultPageSize}
            lazy={true}
            emptyMessage={t('No_records_found')}
            //onLazyLoad={e => setLazyLoad(e)}
            onLazyLoad={(e) => props.onLazyLoad(e)}
          />
        ) : (
          <LegoTable
            showSkeleton={props.showSkeleton}
            dataKey={props.dataKey}
            rows={props.dataPage.rows}
            sortField={props.sortField}
            sortOrder={props.sortOrder}
            first={props.dataPage.first}
            preSelected={props.preSelected}
            rowClassName={props.rowClassName}
            totalDue={props.dataPage.totalDue}
            totalPaid={props.dataPage.totalPaid}
            totalRecords={props.dataPage.totalRecords}
            dataSourceInplace={props.dataSourceInplace}
            rowTemplateAppend={props.rowTemplateAppend}
            showExportButton={props.showExportButton}
            showExpandButton={props.showExpandButton}
            showArchivedButton={props.showArchivedButton}
            rowExpansionTemplate={props.rowTemplateInplace}
            showOnPatientDetailsScreen={showOnPatientDetailsScreen}
            showInCard={showInCard}
            layoutMode={layoutMode}
            filters={filters}
            fitRows={fitRows}
            page_info={props.dataPage}
            expandedRows={expandedRows}
            invalidFields={invalidFields}
            columns={columns}
            actions={actions}
            onExportClick={onExportClick}
            isMobile={isMobile}
            isTablet={isTablet}
            isFetching={isFetching}
            iPadInPortrait={iPadInPortrait}
            showDataScroller={showDataScroller}
            isMobileDataTableView={isMobileDataTableView}
            isMobileOnly={isMobileOnly}
            iPadProInLandscape={iPadProInLandscape}
            contextMenu={props.contextMenu}
            paginatorLeft={props.paginatorLeft}
            dataOnPageAppend={dataOnPageAppend}
            dataFieldFilters={dataFieldFilters}
            dataOnPageInplace={dataOnPageInplace}
            resetPreSelected={props.resetPreSelected}
            mobileRowExpansionSkeleton={props.mobileRowExpansionSkeleton}
            showRemoveFiltersForMoreRecords={showRemoveFiltersForMoreRecords}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            setExpanded={setExpanded}
            setFieldFilter={setFieldFilter}
          />
        )}
      </div>
    </Context.Provider>
  );
};

export default LegoView;
