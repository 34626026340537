import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Ripple } from 'primereact/ripple';
import { Toast } from 'primereact/toast';
import { Tooltip } from 'primereact/tooltip';
import { CSSTransition } from 'react-transition-group';
import { patientDetailsTabs } from './modules/patients/components/PatientDetails/helpers/patientDetailsTabs';
import {
  setDefaultClaimsTableValues,
  setClaimInfo,
  setResetClaimForm,
  updateWSBCRecord,
  updateTeleplanInvoice
} from './modules/claims/actions/claims.action.creators';
import {
  setActiveTab,
  setPatientInfo,
  setEligibility,
  setDefaultPatientsTableValues,
  setPatientIdForHighlightInTable
} from './modules/patients/actions/patients.action.creators';
import { isEmpty } from 'lodash';
import cx from 'classnames';
import { resetPrivateForm } from './modules/claims/components/PrivateDetails/claimDetailsHelpers/resetPrivateForm';
import { setDashboardActiveTabIndex } from './modules/dashboard/actions/dashboard.action.creator';
import { routes } from './routes/routes';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setClaimNotes, setPatientNotes } from './modules/notes/actions/actions';
import { Badge } from 'primereact/badge';
import { sessionStorageKeys } from './modules/config/localStorageKeysConfig';

const AppSubmenu = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isMobile } = useSelector((state) => state.core.window);
  const { dashboardActiveTabIndex } = useSelector((state) => state.dashboard);
  const { claim_details, resetClaimForm, privateRecord, wsbcRecord, teleplanInvoice } = useSelector((state) => state.claims);
  const claimNoteDetails = useSelector((state) => state.notes.claimNoteDetails);
  const patientNoteDetails = useSelector((state) => state.notes.patientNoteDetails);
  const totalUnreadNotes = useSelector((state) => state.notes.totalUnreadNotes);
  const [activeIndex, setActiveIndex] = useState(null);

  const onMenuItemClick = (event, item, index) => {
    //reset active tab index on dashboard page
    dashboardActiveTabIndex !== 0 && dispatch(setDashboardActiveTabIndex(0));
    const lockClaimFilters = JSON.parse(sessionStorage.getItem(sessionStorageKeys.lockClaimFilters));

    //reset claim form
    if (resetClaimForm) {
      !isEmpty(privateRecord) && resetPrivateForm(); // reset private form
      !isEmpty(wsbcRecord) && dispatch(updateWSBCRecord({})); // reset WSBC Record form
      dispatch(setResetClaimForm(false));
    }

    // Reset teleplan invoice
    if (!isEmpty(teleplanInvoice)) dispatch(updateTeleplanInvoice({}));

    //set default values for data table
    !lockClaimFilters && dispatch(setDefaultClaimsTableValues());
    dispatch(setDefaultPatientsTableValues());

    //reset highlighted element for DataScroller
    !isEmpty(claim_details) && dispatch(setClaimInfo({}));
    dispatch(setPatientInfo({}));

    // Reset memos page
    if (!isEmpty(claimNoteDetails)) dispatch(setClaimNotes({}));
    if (!isEmpty(patientNoteDetails)) dispatch(setPatientNotes({}));

    //===PATIENT PROFILE===//
    //set default active tab for PatientDetails
    dispatch(setActiveTab(patientDetailsTabs.profile));
    //reset temporary eligibility message
    dispatch(setEligibility({}));
    //reset highlighted patient
    dispatch(setPatientIdForHighlightInTable({}));
    //===================//

    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    if (props.root && props.onRootItemClick) {
      props.onRootItemClick({
        originalEvent: event,
        item: item
      });
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
      event.preventDefault();
    }

    if (item.items) {
      setActiveIndex(activeIndex === index ? null : index);
    }

    if (props.onMenuItemClick) {
      props.onMenuItemClick({
        originalEvent: event,
        item: item
      });
    }
  };

  const onKeyDown = (event, item, index) => {
    if (event.key === 'Enter') {
      onMenuItemClick(event, item, index);
    }
  };

  const onMenuItemMouseEnter = (index) => {
    if (props.root && props.menuActive && isHorizontal() && !isMobile) {
      setActiveIndex(index);
    }
  };

  const isHorizontalOrSlim = useCallback(() => {
    return props.layoutMode === 'horizontal' || props.layoutMode === 'slim';
  }, [props.layoutMode]);

  useEffect(() => {
    if (!props.menuActive && isHorizontalOrSlim() && !isMobile) {
      setActiveIndex(null);
    }
  }, [props.menuActive, isHorizontalOrSlim, isMobile]);

  const isHorizontal = () => {
    return props.layoutMode === 'horizontal';
  };

  const isSlim = () => {
    return props.layoutMode === 'slim';
  };

  const renderLinkContent = (item) => {
    let submenuIcon = item.items && <i className="pi pi-angle-down layout-submenu-toggler" />;
    let badge = item.badge && <span className="menuitem-badge">{item.badge}</span>;
    const totalUnreadSum = totalUnreadNotes?.reduce((sum, item) => sum + item.TotalUnread, 0);
    const totalUnread = totalUnreadSum > 99 ? '99+' : totalUnreadSum;

    return (
      <React.Fragment>
        <i className={cx(item.icon, 'layout-menuitem-icon p-overlay-badge')} style={{ ...item.style, marginRight: '7px' }}>
          {item.id === 'memos' && totalUnreadSum > 0 && <Badge value={totalUnread} severity="warning" style={{ marginTop: '-4px' }} />}
          {item.isNew && <Badge value="New" severity="info" style={{ marginTop: '-4px' }} />}
        </i>
        <span className="menuitem-text">{item.label}</span>
        {badge}
        {submenuIcon}
      </React.Fragment>
    );
  };

  const renderLink = (item, i) => {
    let content = renderLinkContent(item);
    let linkStyle = cx(item.className, 'p-ripple');
    const pathname = location.pathname;
    const showLegacyButton =
      pathname === routes.dashboard.path ||
      pathname === routes.patients.path ||
      pathname === routes.invoices.path ||
      pathname === routes.staff.path ||
      pathname === routes.reports.path ||
      pathname === routes.support.path ||
      pathname === routes.appointments.path;

    if (item.to) {
      return (
        <>
          <Tooltip target=".mainMenuButtons" position="right" baseZIndex={999999} />
          <NavLink
            style={{ padding: '15px 10px' }}
            className={cx('mainMenuButtons font-bold', linkStyle)}
            data-pr-tooltip={props.layoutMode === 'overlay' && !isMobile ? item.label : null}
            exact
            tabIndex={-1}
            to={item.to}
            target={item.target}
            role="menuitem"
            //activeClassName="router-link-active"
            activeClassName="navMenuActive"
            onClick={(e) => onMenuItemClick(e, item, i)}
            // onMouseEnter={() => onMenuItemMouseEnter(i)}
          >
            {content}
            <Ripple />
          </NavLink>
        </>
      );
    } else {
      if (showLegacyButton) {
        return (
          <>
            <Tooltip target=".mainSubMenuButtons" position="right" baseZIndex={999999} />
            <a
              style={{ padding: '15px 10px' }}
              className={cx('mainSubMenuButtons font-bold', linkStyle)}
              data-pr-tooltip={props.layoutMode === 'overlay' && !isMobile ? item.label : null}
              role="menuitem"
              href={item.url}
              target={item.target}
              tabIndex={-1}
              onKeyDown={(e) => onKeyDown(e, item, i)}
              onClick={(e) => onMenuItemClick(e, item, i)}
              onMouseEnter={(e) => onMenuItemMouseEnter(i)}
            >
              {content}
              <Ripple />
            </a>
          </>
        );
      } else {
        return null;
      }
    }
  };

  var items =
    props.items &&
    props.items.map((item, i) => {
      let active = activeIndex === i;
      let styleClass = cx(item.badgeStyleClass, { 'active-menuitem': active }, { 'layout-root-menuitem': props.root });

      return (
        <li className={cx(styleClass, 'py-0')} key={i} role="none">
          {renderLink(item, i)}

          <CSSTransition
            classNames="layout-submenu-container"
            timeout={{ enter: 400, exit: 400 }}
            in={
              item.items &&
              (props.root && !((isHorizontal() || isSlim()) && !isMobile && (!isSlim() || (isSlim() && activeIndex !== null))) ? true : active)
            }
            unmountOnExit
          >
            <AppSubmenu
              items={item.items}
              // showWarn={props.showWarn}
              isMobile={isMobile}
              onMenuItemClick={props.onMenuItemClick}
              layoutMode={props.layoutMode}
              menuActive={props.menuActive}
              parentMenuItemActive={active}
            />
          </CSSTransition>
        </li>
      );
    });

  return (
    <ul role="menu" className={props.className}>
      {items}
    </ul>
  );
};

const AppMenu = (props) => {
  const toast = useRef(null);
  return (
    <>
      <AppSubmenu
        className="layout-menu"
        root={true}
        parentMenuItemActive={true}
        items={props.model}
        menuActive={props.active}
        layoutMode={props.layoutMode}
        onMenuItemClick={props.onMenuItemClick}
        onRootItemClick={props.onRootMenuItemClick}
        // showWarn={showWarn}
      />

      <Toast ref={toast} />
    </>
  );
};

export default AppMenu;
