import React from 'react';
import { useDispatch } from 'react-redux';
import RemoveFiltersForMoreRecords from './RemoveFiltersForMoreRecords';
import { setClaimFilters } from '../claims/actions/claims.action.creators';
import cx from 'classnames';
import moment from 'moment';
import classes from './LegoChips.module.scss';
import { getItemFromArray, getChipValue, getChipValueCSV } from '../../Helper';
import { chipLables, legoFilters } from './helpers/legoFilters';
import { Button } from 'primereact/button';
import { t } from '../../service/localization/i18n';

const LegoChips = ({
  query,
  columns,
  filters,
  totalAlter,
  layoutMode,
  totalFound,
  extraFields,
  isMobile,
  isMobileOnly,
  iPadProInLandscape,
  setFieldFilter,
  clearFilters,
  showOnPatientDetailsScreen = false,
  clearFiltersExceptFreetext
}) => {
  const dispatch = useDispatch();

  const thisYear = new Date().getFullYear();
  let items = filters || {};

  const removeItem = (item, value) => {
    if (thisYear === Number(value)) {
      dispatch(setClaimFilters('', item));
    }
    setFieldFilter({ value: '', target: { value: '' } }, item);
  };

  const removeOneItem = (items, field, value) => {
    const item = items[field].split(',');
    const clean = value.trim();
    let updated = item.filter((x) => x !== clean);
    if (updated.length === item.length) {
      updated = item.filter((x) => x.indexOf(clean) === -1);
    }
    const newvalue = updated.join();
    setFieldFilter({ value: newvalue, target: { value: newvalue } }, field);
  };

  const cleanDateChip = (value) => {
    var reply = value;
    if (reply && typeof reply === 'string') {
      reply = reply.replace(/y/g, '').replace(/d/g, '').replace(/m/g, '').trim();
      while (reply.endsWith('/')) {
        reply = reply.substring(0, reply.length - 1);
      }
    }
    return reply;
  };

  if (query && query !== '') {
    items = { ...items, ...query };
    filters = { ...items };
  }

  const createChip = (items, field, key, label, value) => {
    return (
      <li key={key} className="p-chips-token p-highlight my-2">
        <span className="p-chips-token-label font-normal">
          {label}: {value}
        </span>
        <span className="p-chips-token-icon pi pi-times-circle" onClick={() => removeOneItem(items, field, value)} />
      </li>
    );
  };

  const chips = Object.keys(items).map((x) => {
    if (x === 'ServiceDate') return;
    if (x === 'InvoiceType' && items[x] === 'All') return;
    if (x === 'StatusText' && (items[x] === 'All' || items[x] === 'All Statuses')) return;
    let displayValue = '';
    const dateFields = ['DOS', 'DOB']; // for now
    const key = 'chip_' + x;
    let isExtra = false;
    let field = columns.find((item) => item.id === x);
    let label = field ? field.header : x;

    //[KS] CMO-964 - Remove name from the doctor's label when invoices are filtered by doctor
    if (chipLables.some((i) => i.id === x)) label = chipLables.find((i) => i.id === x).label;

    if (label === 'SubmissionCode') label = 'Submission';

    if (!!field === false) {
      field = extraFields?.find((item) => item.id === x);
      if (field) isExtra = true;
    }

    if (items && items[x]) {
      const dateDiff = moment(filters.PeriodTo).diff(filters.PeriodFrom, 'days');
      displayValue = items[x];

      if (typeof items[x] === 'string') {
        displayValue = items[x].toUpperCase();
      }

      if (dateFields.indexOf(label) > -1) {
        displayValue = cleanDateChip(items[x]);
      }

      if (isExtra) {
        let suspect;
        if (items[x] === -2 || items[x] === '-2') return;
        if (field.field.indexOf('Guid') > -1) {
          if (Array.isArray(field.list)) {
            suspect = getItemFromArray(field.list, items[x], field.field);
            if (suspect) {
              displayValue = `${suspect.FullName}`;
            }
          } else {
            // PatientGuid for now
            const realValue = field.list[field.field];
            if (realValue && realValue.indexOf(',') > -1) {
              displayValue = getChipValueCSV(realValue).join(', ');
            } else {
              displayValue = getChipValue(realValue);
            }
          }
        } else {
          if (typeof field.list[0].value === typeof items[x]) {
            displayValue = getItemFromArray(field.list, items[x]).label;
          } else {
            suspect = getItemFromArray(field.list, parseInt(items[x], 10));
            displayValue = suspect ? suspect.label : '';
          }
        }
      }

      // Custom period for Submission Date
      if ((filters.SubmissionPeriodFrom?.length > 0 || filters.SubmissionPeriodTo?.length > 0) && x === 'SubmissionPeriod' && items[x] === '7') {
        if (filters.SubmissionPeriodFrom?.length > 0 && filters.SubmissionPeriodTo?.length === 0) {
          displayValue = `from ${filters.SubmissionPeriodFrom}`;
        }

        if (filters.SubmissionPeriodFrom?.length === 0 && filters.SubmissionPeriodTo?.length > 0) {
          displayValue = `before ${filters.SubmissionPeriodTo}`;
        }

        if (filters.SubmissionPeriodFrom?.length > 0 && filters.SubmissionPeriodTo?.length > 0) {
          if (dateDiff === 0) {
            displayValue = filters.SubmissionPeriodFrom;
          } else displayValue = `${filters.SubmissionPeriodFrom} - ${filters.SubmissionPeriodTo}`;
        }
      }

      if (filters.ServiceDate?.length > 0 && x === 'SubmissionPeriod' && items[x] === '7') {
        const isYearSame = moment(filters.SubmissionPeriodTo).isSame(filters.SubmissionPeriodFrom, 'year');
        const isMonthSame = moment(filters.SubmissionPeriodTo).isSame(filters.SubmissionPeriodFrom, 'month');

        if (dateDiff === 0) {
          displayValue = filters.ServiceDate;
        }

        if (dateDiff > 26 && dateDiff < 31 && isMonthSame) {
          displayValue = filters.ServiceDate.slice(0, 7);
        }

        if ((dateDiff === 364 || dateDiff === 365) && isYearSame) {
          displayValue = filters.ServiceDate.slice(0, 4);
        }
      }

      // Custom period for Service Date
      if ((filters.PeriodFrom?.length > 0 || filters.PeriodTo?.length > 0) && x === 'Period' && items[x] === '7') {
        if (filters.PeriodFrom.length > 0 && filters.PeriodTo.length === 0) {
          displayValue = `from ${filters.PeriodFrom}`;
        }

        if (filters.PeriodFrom.length === 0 && filters.PeriodTo.length > 0) {
          displayValue = `before ${filters.PeriodTo}`;
        }

        if (filters.PeriodFrom.length > 0 && filters.PeriodTo.length > 0) {
          if (dateDiff === 0) {
            displayValue = filters.PeriodFrom;
          } else displayValue = `${filters.PeriodFrom} - ${filters.PeriodTo}`;
        }
      }

      //CMO-135 chip should display short period representation if it can be derived from the period
      if (filters.ServiceDate?.length > 0 && x === 'Period' && items[x] === '7') {
        const isYearSame = moment(filters.PeriodTo).isSame(filters.PeriodFrom, 'year');
        const isMonthSame = moment(filters.PeriodTo).isSame(filters.PeriodFrom, 'month');

        if (dateDiff === 0) {
          displayValue = filters.ServiceDate;
        }

        if (dateDiff > 26 && dateDiff < 31 && isMonthSame) {
          displayValue = filters.ServiceDate.slice(0, 7);
        }

        if ((dateDiff === 364 || dateDiff === 365) && isYearSame) {
          displayValue = filters.ServiceDate.slice(0, 4);
        }
      }
    }

    if ((items[x] && field) || (x === 'Comment' && displayValue)) {
      if (
        displayValue &&
        typeof displayValue === 'string' &&
        displayValue.indexOf(',') > -1 &&
        field.field.indexOf('Guid') === -1 &&
        field.field.indexOf('FullName') === -1 // CMO-2892 - Invoice panel - Not able to discard chips when names is separated in two chips
      ) {
        return displayValue.split(',').map((one, index) => {
          const newKey = `${key}_${index}`;
          return createChip(items, x, newKey, label, one);
        });
      }

      if (x === 'Period' && items[x] === '7' && filters.PeriodFrom.length === 0 && filters.PeriodTo.length === 0) return;
      if (x === 'SubmissionPeriod' && items[x] === '7' && filters.SubmissionPeriodFrom?.length === 0 && filters.SubmissionPeriodTo?.length === 0)
        return;

      if (label === 'DoctorGuid') label = 'Doc';
      if (label === 'PatientGuid') label = 'Patient';
      if (label === 'SubmissionPeriod') label = 'Submission Period';
      if (label === 'RemittancePeriod') label = 'Remittance Period';
      if (label === 'LocationCode') label = 'Location Code';
      if (label === 'PayeeNumber') label = t('MSP_Payee_Number');

      return (
        <li key={key} className="p-chips-token p-highlight my-1">
          <span className="p-chips-token-label font-normal">
            {label !== '' ? `${label}:` : null} {displayValue}
          </span>
          <span className="p-chips-token-icon pi pi-times-circle" onClick={() => removeItem(x, items[x])} />
        </li>
      );
    }
  });

  const overlayClasses = layoutMode === 'overlay' ? classes.rootOverlay : layoutMode === 'static' ? classes.rootStatic : '';

  const root = iPadProInLandscape ? overlayClasses : isMobile ? classes.rootMobile : classes.rootDesctop;

  return (
    <div className={cx('p-chips flex px-0 py-1', showOnPatientDetailsScreen ? '' : isMobileOnly ? classes.rootMobileOnly : root)}>
      {/* <Chip label="Action" className="mr-2 my-1" /> */}

      <div className="flex p-0 col-12">
        <ul className="p-chips-multiple-container block py-0">
          {chips}

          {chips?.filter((i) => !!i)?.length > 1 && <Button className="p-button-text py-2 mb-1" label={t('Clear_all')} onClick={clearFilters} />}

          {totalAlter !== undefined && totalAlter !== totalFound && !isMobileOnly && (
            <RemoveFiltersForMoreRecords totalAlter={totalAlter} totalFound={totalFound} clearFiltersExceptFreetext={clearFiltersExceptFreetext} />
          )}
        </ul>
      </div>
    </div>
  );
};

export default LegoChips;
