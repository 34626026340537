import React from 'react';

import { Dropdown } from 'primereact/dropdown';

import { dropdownScrollHeight } from '../../../modules/config/stylesConfig';
import { provinces_list } from '../../../modules/config/constants';
import cx from 'classnames';

// interface IGender {
//   name: string,
//   value: string,
//   onChange: (e) => void,
//   errorMessage?: string,
//   optionLabel?: "label" | "value",
//   ref?: React.Ref<HTMLInputElement>
//   disabled?: boolean
//   className?: string
//   placeholder?: string
//   style?: string
//   options?: any[]
// };

const ProvincePicker = React.forwardRef((props, ref) => {
  const handleChange = (value) => {
    props.onChange && props.onChange(value);
  };

  return (
    <Dropdown
      className={cx({ 'p-invalid': props.errorMessage }, props.className || '')}
      style={props.style || { minWidth: '80px' }}
      id={props.name}
      name={props.name}
      inputRef={ref}
      optionLabel={props.optionLabel || 'label'} // availible options "label" or "value"
      scrollHeight={dropdownScrollHeight()}
      placeholder={props.placeholder || ''}
      options={props.options || provinces_list}
      disabled={props.disabled}
      value={props.value}
      onChange={handleChange}
    />
  );
});

export default ProvincePicker;
