import React from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import AuthFormWrap from '../../../../components/Wraps/PageWrap/AuthFormWrap';
import { InputWrapper } from '../../../../components/Inputs';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';

import { backdoor, getUserProfileData, updateUser } from '../../actions/auth.actions.creators';
import { setAppMode } from '../../../core/actions/core.action.creators';
import { elementIDs } from '../../../config/elementIDsConfig';
import { t } from '../../../../service/localization/i18n';
import qs from 'qs';
import { routes } from '../../../../routes/routes';

const BackdoorSignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const query_str = window.location.search ? window.location.search.replace('?', '') : '';
  const { code, DoctorGuid, DCNGuid } = qs.parse(query_str);
  const { control, formState, handleSubmit } = useForm({ defaultValues: { adminCode: code, password: '' } });
  const passwordErrorMessage = formState.errors?.password?.message;

  const onSubmit = async (data) => {
    try {
      const payload = {
        DCNGuid,
        DoctorGuid,
        AdminCode: data.adminCode,
        password: data.password
      };

      const result = await backdoor(payload);

      if (result) {
        const decodedScope = ['d', 'p', 'c', 's', 'r', 'u'];
        result.decoded = { scope: decodedScope };

        const appMode = 'live';
        const readOnly = !!payload['ro'];

        dispatch(setAppMode(appMode, !readOnly, 'code'));
        dispatch(updateUser(result));
        const userProfile = await getUserProfileData(result);
        if (userProfile) {
          history.push(routes.dashboard.path);
        }
      }
    } catch (error) {
      console.error('!!!Error during backdoor logining', error);
    }
  };

  return (
    <AuthFormWrap label="Welcome to Claim Manager">
      <form onSubmit={handleSubmit(onSubmit)} name="BackdoorSignIn">
        <div className="flex flex-column gap-3">
          <Controller
            name="adminCode"
            control={control}
            render={({ field }) => (
              <InputWrapper className="h-auto" name="adminCode" label="Temporary Code">
                <InputText alt="Temporary Code" type="text" id={elementIDs.usernameInput} {...field} disabled />
              </InputWrapper>
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: t('Mandatory_field.1') }}
            render={({ field }) => (
              <InputWrapper className="h-auto" name="password" label="Admin Password" errorMessage={passwordErrorMessage}>
                <Password
                  className={passwordErrorMessage ? 'p-invalid' : ''}
                  id={elementIDs.passwordInput}
                  value={field.value}
                  alt="Admin Password"
                  inputRef={field.ref}
                  feedback={false}
                  toggleMask
                  onChange={(e) => field.onChange(e)}
                />
              </InputWrapper>
            )}
          />

          <Button id={elementIDs.logInButton} type="submit" className="h-3rem mt-3" label={t('Login')} loading={formState.isSubmitting} />
        </div>
      </form>
    </AuthFormWrap>
  );
};

export default BackdoorSignIn;
