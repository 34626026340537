import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputWrapper } from '../../../Inputs';
import { Dropdown } from 'primereact/dropdown';

import { dropdownScrollHeight } from '../../../../modules/config/stylesConfig';
import { provinces_list } from '../../../../modules/config/constants';
import { t } from '../../../../service/localization/i18n';

const Province = () => {
  const name = 'Province';
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <InputWrapper name={name} label={t('Province')}>
          <Dropdown value={field.value} scrollHeight={dropdownScrollHeight()} options={provinces_list} onChange={(e) => field.onChange(e.value)} />
        </InputWrapper>
      )}
    />
  );
};

export default Province;
