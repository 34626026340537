import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PHN from './PHN';
import InputWrapper from '../InputWrapper/InputWrapper';
import ProvincePicker from '../Province/ProvincePicker';

import { validatePHN } from '../../../modules/patients/actions/patients.action.creators';
import { commonInputs } from '../../../modules/claims/../config/commonInputsConfig';
import { provinces_list } from '../../../modules/config/constants';
import cx from 'classnames';

import './PHNInput.scss';

// interface IPHNWithProvince {
// values: {
//   phn: string,
//   province: string
// }
//   provincenInput: {
//     name: string,
//     label: string,
//   },
//   phnInput: {
//     name: string,
//     label: string,
//   },
//   provinceValidationCallback: (isValid) => void,
//   onProvinceChange: (value) => void,
//   onPHNChange: (value) => void,
//   onPHNBlur: (value) => void,

//   required?: boolean,
//   autoFocus?: boolean,
//   gap?: boolean,
//   errorMessage?: string
// };

const PHNWithProvince = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { isFetchingPHNValidation } = useSelector((state) => state.patients);
  const [prevPHNValue, setPrevPHNValue] = useState(props.values.phn?.replaceAll(' ', '') || '');

  const phnInput = props.phnInput || commonInputs.phn;
  const phnProvince = props.provincenInput || commonInputs.phnProvince;

  const handleProvinceChange = (e) => {
    const value = e.target.value;
    const phnForRequest = props.values.phn ? `${value}${props.values.phn}` : '';

    !phnForRequest && props.onProvinceChange({ value, isValid: true });

    props.onProvinceChange(value);

    // [KS] CMO-1281 - Prevent creation of duplicate patient records (PHN check)
    phnForRequest &&
      dispatch(
        validatePHN(phnForRequest, (isValid) => {
          props.provinceValidationCallback({ phnProvince: value, isValid });
        })
      );
  };

  const handlePHNChange = (e) => {
    props.onPHNChange(e.target.value);
  };

  const handlePHNBlur = (e) => {
    const value = e.target.value;
    const phnForRequest = value ? `${props.values.province}${value}` : '';
    const isNewPHN = value?.replaceAll(' ', '')?.replaceAll('_', '') !== prevPHNValue?.replaceAll(' ', '')?.replaceAll('_', '');

    // [KS] CMO-1281 - Prevent creation of duplicate patient records (PHN check)
    isNewPHN &&
      dispatch(
        validatePHN(phnForRequest, (isValid) => {
          props.onPHNBlur({ value, isValid });
        })
      );

    setPrevPHNValue(e.target.value?.replaceAll(' ', ''));
  };

  return (
    <InputWrapper name={phnInput.name} label={phnInput.label} required={props.required}>
      <div className="flex">
        <div className={props.gap ? 'mr-2' : ''}>
          <ProvincePicker
            className={props.gap ? '' : 'phnProvinceInput'}
            style={{ maxWidth: '76px' }}
            name={phnProvince.name}
            optionLabel="value"
            options={provinces_list}
            value={props.values.province}
            onChange={handleProvinceChange}
          />
        </div>

        <div className="w-full">
          <span className="p-input-icon-right w-full">
            {isFetchingPHNValidation && <i className="pi pi-spin pi-spinner" />}
            <PHN
              ref={ref}
              className={cx({ 'p-invalid': props.errorMessage, phnInput: !props.gap })}
              name={phnInput.name}
              autoFocus={props.autoFocus}
              province={props.values.province || 'BC'}
              value={props.values.phn?.trim() || ''}
              onChange={handlePHNChange}
              onBlur={handlePHNBlur}
            />
          </span>
          {props.errorMessage && (
            <div id={`${phnInput.name}-error-message`} className="p-error text-sm mt-1" style={{ minWidth: '100px' }}>
              {props.errorMessage}
            </div>
          )}
        </div>
      </div>
    </InputWrapper>
  );
});

export default PHNWithProvince;
