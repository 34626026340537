import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import CommonInputField from '../../../common/components/CommonInputField';

import { address_types, email_types, phone_types } from '../../../config/constants';
import { toTitleCase } from '../../../utils/toTitleCase';
import { formatZipCode } from '../../../utils/formatZipCode';
import { dropdownScrollHeight } from '../../../config/stylesConfig';
import { t } from '../../../../service/localization/i18n';
import cx from 'classnames';
import { provincesListWithEmptyOption } from '../../../config/dropdownListsConfig';

const Address = ({
  formik,
  showContacts = true,
  showAddressType = true,
  showAddressLine = true,
  isFormFieldValid,
  getFormErrorMessage,
  inputFieldClassName
}) => {
  return (
    <>
      {/* Address */}
      {showAddressLine && (
        <CommonInputField
          className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
          label={t('Street Address')}
          required={formik.values.PHNProvince !== 'BC'}
        >
          <InputText
            id="AddressLine"
            name="AddressLine"
            value={formik.values.AddressLine}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue('AddressLine', toTitleCase(e.target.value));
            }}
          />
        </CommonInputField>
      )}
      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        label={t('City')}
        required={formik.values.PHNProvince !== 'BC'}
      >
        <InputText
          id="City"
          name="City"
          value={formik.values.City}
          onChange={(e) => {
            formik.handleChange(e);
            formik.setFieldValue('City', toTitleCase(e.target.value));
          }}
        />
      </CommonInputField>

      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        label={t('Province')}
        required={formik.values.PHNProvince !== 'BC'}
      >
        <Dropdown
          className={cx({ 'p-invalid': isFormFieldValid('Province') })}
          id="Province"
          name="Province"
          scrollHeight={dropdownScrollHeight()}
          options={provincesListWithEmptyOption}
          value={formik.values.Province}
          onChange={formik.handleChange}
        />
      </CommonInputField>

      <CommonInputField
        className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
        label={t('Postal_Code')}
        errorMessage={getFormErrorMessage('Zip')}
      >
        <InputText
          id="Zip"
          name="Zip"
          value={formik.values.Zip}
          onChange={(e) => {
            formik.handleChange(e);
            formik.setFieldValue('Zip', formatZipCode(e.target.value));
          }}
        />
      </CommonInputField>

      <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 py-0 px-2 mb-1'} label={t('FacilityName')}>
        <InputText
          id="Facility"
          name="Facility"
          value={formik.values.Facility}
          onChange={(e) => {
            formik.handleChange(e);
          }}
        />
      </CommonInputField>

      {formik.values.AddressLine?.length > 0 && showAddressType && (
        <CommonInputField className="col-12 py-0 px-2 mb-1" label={t('Address_Type')}>
          <Dropdown
            inputId="AddressTypeInput"
            id="AddressType"
            name="AddressType"
            options={address_types}
            value={formik.values.AddressType}
            onChange={formik.handleChange}
          />
        </CommonInputField>
      )}

      {/* Contacts */}
      {showContacts && (
        <>
          <CommonInputField
            className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'}
            label={t('Email')}
            errorMessage={getFormErrorMessage('Email')}
          >
            <InputText
              className={cx({
                'p-invalid': isFormFieldValid('Email')
              })}
              id="Email"
              name="Email"
              placeholder="mail@example.com"
              value={formik.values.Email}
              onChange={formik.handleChange}
            />
          </CommonInputField>

          <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'} label={t('Email Type')}>
            <Dropdown
              inputId="EmailTypeInput"
              id="EmailType"
              name="EmailType"
              options={email_types}
              value={formik.values.EmailType}
              onChange={formik.handleChange}
            />
          </CommonInputField>

          <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'} label={t('Phone')}>
            <InputMask
              id="Phone"
              name="Phone"
              inputMode="numeric"
              mask="(999) 999-9999"
              placeholder="(999) 999-9999"
              value={formik.values.Phone}
              onChange={formik.handleChange}
            />
          </CommonInputField>

          <CommonInputField className={inputFieldClassName ? inputFieldClassName : 'col-12 xl:col-6 py-0 px-2 mb-1'} label={t('Phone Type')}>
            <Dropdown
              inputId="PhoneTypeInput"
              id="PhoneType"
              name="PhoneType"
              options={phone_types}
              value={formik.values.PhoneType}
              onChange={formik.handleChange}
            />
          </CommonInputField>
        </>
      )}
    </>
  );
};

export default Address;
